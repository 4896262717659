import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
function SSS({ data, param, type }) {
  console.log(type)
  return (
    <StaticQuery
      query={graphql`
        {
          sss: allMarkdownRemark(
            filter: { frontmatter: { type: { in: "sss-item" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  content
                  summary
                  date
                  parent
                  type
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div itemscope="" itemtype="https://schema.org/FAQPage">
          <h3 className="h4 mt-5">Sıkça Sorulan Sorular</h3>
          {data.sss.edges.map(({ node }) => {
            if (node.frontmatter.parent === param) {
              if (type === 'sss-item') {
                return (
                  <div className="sss-link">
                    <Link to={node.fields.slug} className="h5 mb-0">
                      {' '}
                      {node.frontmatter.title}
                    </Link>
                  </div>
                )
              } else {
                return (
                  <ExpansionPanel
                    key={node.id}
                    itemprop="mainEntity"
                    itemscope=""
                    itemtype="https://schema.org/Question">
                    <ExpansionPanelSummary
                      expandIcon=""
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <h2 className="h5 mb-0" itemprop="name">
                        {' '}
                        {node.frontmatter.title}
                      </h2>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      itemprop="acceptedAnswer"
                      itemscope=""
                      itemtype="https://schema.org/Answer">
                      <div itemprop="text"> {node.frontmatter.content}</div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              }
            }
          })}
        </div>
      )}
    />
  )
}
export default SSS
