import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Html, SEO } from '../components'
import PageHeader from '../components/page-header'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import SSS from '../components/SSS'
const SssBlock = ({ data: { edges } }) => {
  return (
    <div>
      <h3 className="h4 mt-5">Sıkça Sorulan Sorular</h3>
      {edges.map(({ node }) => (
        <ExpansionPanel key={node.frontmatter.title}>
          <ExpansionPanelSummary
            expandIcon=""
            aria-controls="panel1a-content"
            id="panel1a-header">
            <h2 className="h5 mb-0"> {node.frontmatter.title}</h2>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Html>{node.frontmatter.content}</Html>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  )
}
const ServiceTemplate = ({
  data: {
    services: { frontmatter },
    sss
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.summary}
        isBlogPost
        datePublished={frontmatter.date}
      />
      <PageHeader>
        <h1>{frontmatter.title}</h1>
        <p>{frontmatter.summary}</p>
        <Link className="btn btn-banner" to="/teklif-al">
          Hemen Teklif Al
        </Link>
      </PageHeader>
      <div className="page-content">
        <div className="container">
          <Html>{frontmatter.content}</Html>
          {/*{<SssBlock data={sss} />}*/}
          {<SSS param={pageContext.parent} type={frontmatter.type}/>}
        </div>
      </div>
    </Layout>
  )
}

export default ServiceTemplate

export const pageQuery = graphql`
  query ServiceBySlug($slug: String) {
    services: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        content
        summary
        date
        type
      }
    }
    sss: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: $slug }
        frontmatter: { type: { in: "sss-item" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            content
            summary
            date
            type
            parent
          }
        }
      }
    }
  }
`
